"use strict";

/**
 * Created by Bryan Marvila on 20/05/2021.
 */

(function () {
  'use strict';

  var suggestionContainerLanguage = {
    title: 'Sugestões de descritores',
    insertAll: 'Inserir todos',
    moreSuggestion: 'Mais sugestões',
    resetSuggestion: 'Reiniciar sugestões',
    suggestion: {
      inflections: {
        title: 'Inflexões',
        noData: 'Não há sugestões de inflexões para o último descritor adicionado'
      },
      related: {
        title: 'Relacionados',
        noData: 'Não há sugestões relacionadas aos descritores inseridos até o momento'
      }
    }
  };
  window.moduleExport({
    suggestionContainerLanguage: suggestionContainerLanguage
  }, typeof module !== 'undefined' && module);
})();